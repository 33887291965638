import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/LogoInvert";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import axios from "axios";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer invert-color",
    topOuterDivider && "has-top-divider",
    className
  );
  const [footerData, setFooterData] = useState(null);
  const [UsefulLink, setUsefulLinkdata] = useState(null);
  const fetchFooterData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/footer"
      );
      setFooterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchUsefulLinkData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/usefulLink"
      );
      setUsefulLinkdata(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchFooterData();
    fetchUsefulLinkData();
  }, []);
  if (!UsefulLink || !footerData) {
    return (
      <>
        <footer {...props} className={classes}>
          <div className="container">
            <div
              className={classNames(
                "site-footer-inner",
                topDivider && "has-top-divider"
              )}
            >
              <div className="footer-top text-xxs">
                <div className="footer-blocks">
                  <div className="footer-block">
                    <Logo className="mb-16" />
                    <div className="footer-copyright">
                      &copy; CliClaim {new Date().getFullYear()}
                    </div>
                  </div>
                  <div className="footer-block">
                    <div className="footer-block-title">Company</div>
                    <ul className="list-reset mb-0">
                      <li>
                        <a href="https://cruip.com/">Dummy text used</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">The purpose of lorem</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">
                          Filler text can be very useful
                        </a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Be on design</a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-block">
                    <div className="footer-block-title">Uses cases</div>
                    <ul className="list-reset mb-0">
                      <li>
                        <a href="https://cruip.com/">Consectetur adipiscing</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Lorem Ipsum is place</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Excepteur sint</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Occaecat cupidatat</a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-block">
                    <div className="footer-block-title">Docs</div>
                    <ul className="list-reset mb-0">
                      <li>
                        <a href="https://cruip.com/">The purpose of lorem</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Dummy text used</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Excepteur sint</a>
                      </li>
                      <li>
                        <a href="https://cruip.com/">Occaecat cupidatat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-bottom space-between center-content-mobile text-xxs">
                <FooterNav />
                <FooterSocial />
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  } else {
    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <div className="footer-copyright">
                    &copy; {footerData.title} {new Date().getFullYear()}
                  </div>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Useful Links</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="https://cruip.com/">Dummy text used</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">The purpose of lorem</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">
                        Filler text can be very useful
                      </a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Be on design</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-block">
                  <div className="footer-block-title">Docs</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="https://cruip.com/">The purpose of lorem</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Dummy text used</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Excepteur sint</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Occaecat cupidatat</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav />
              <FooterSocial />
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
