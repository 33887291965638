import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import axios from "axios";

const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/appService"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Features",
    paragraph: "",
  };
  if (!data) {
    return (
      <>
        <section {...props} className={outerClasses}>
          <div className="container">
            <div className={innerClasses}>
              <SectionHeader data={sectionHeader} className="center-content" />
              <div className={tilesClasses}>
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className="tiles-item reveal-from-bottom"
                  data-reveal-container=".tiles-wrap"
                >
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16">
                        <Image
                          src={require("./../../assets/images/feature-tile-icon-01.svg")}
                          alt="Features tile icon 01"
                          width={64}
                          height={64}
                        />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="mt-0 mb-8">Robust Workflow</h4>
                      <p className="m-0 text-sm">sqd</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/wallet.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[0].title}</h4>
                    <p className="m-0 text-sm">{data[0].description}</p>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[1].title}</h4>
                    <p className="m-0 text-sm">{data[1].description}</p>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[2].title}</h4>
                    <p className="m-0 text-sm">{data[2].description}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[3].title}</h4>
                    <p className="m-0 text-sm">{data[3].title}</p>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[4].title}</h4>
                    <p className="m-0 text-sm">{data[4].description}</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{data[5].title}</h4>
                    <p className="m-0 text-sm">{data[5].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

FeaturesTiles.propTypes = {
  ...SectionTilesProps.types,
};

FeaturesTiles.defaultProps = {
  ...SectionTilesProps.defaults,
};

export default FeaturesTiles;
