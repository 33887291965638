import React, { useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views
import Home from "./views/Home";
import Secondary from "./views/Secondary";
import Login from "./views/Login";
import Signup from "./views/Signup";
import TermOfUse from "./components/sections/TermOfUse";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("is-loaded");
    scrollRevealRef.current.init();
  }, []);

  const scrollRevealRef = React.useRef(null);

  useEffect(() => {
    scrollRevealRef.current.init();
  }, [location.pathname]);

  return (
    <ScrollReveal
      ref={scrollRevealRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/termofuse"
            component={TermOfUse}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/secondary"
            component={Secondary}
            layout={LayoutAlternative}
          />
          <AppRoute
            exact
            path="/login"
            component={Login}
            layout={LayoutSignin}
          />
          <AppRoute
            exact
            path="/signup"
            component={Signup}
            layout={LayoutSignin}
          />
        </Switch>
      )}
    />
  );
};

export default App;
