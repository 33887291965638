import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "team section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "team-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Team",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
          />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
            >
              <div className="tiles-item-inner">
                <div className="team-item-header">
                  <div className="team-item-image mb-24">
                    <Image
                      src={require("./../../assets/images/team-member-01.jpg")}
                      alt="Team member 01"
                      width={180}
                      height={180}
                    />
                  </div>
                </div>
                <div className="team-item-content">
                  <h5 className="team-item-name mt-0 mb-4">Markus Hasinika</h5>
                  <div className="team-item-role text-xs fw-600 mb-8">
                    Founder & CEO
                  </div>
                  <p className="m-0 text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
            >
              <div className="tiles-item-inner">
                <div className="team-item-header">
                  <div className="team-item-image mb-24">
                    <Image
                      src={require("./../../assets/images/team-member-01.jpg")}
                      alt="Team member 01"
                      width={180}
                      height={180}
                    />
                  </div>
                </div>
                <div className="team-item-content">
                  <h5 className="team-item-name mt-0 mb-4">Markus Hasinika</h5>
                  <div className="team-item-role text-xs fw-600 mb-8">
                    Founder & CEO
                  </div>
                  <p className="m-0 text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
            >
              <div className="tiles-item-inner">
                <div className="team-item-header">
                  <div className="team-item-image mb-24">
                    <Image
                      src={require("./../../assets/images/team-member-01.jpg")}
                      alt="Team member 01"
                      width={180}
                      height={180}
                    />
                  </div>
                </div>
                <div className="team-item-content">
                  <h5 className="team-item-name mt-0 mb-4">Markus Hasinika</h5>
                  <div className="team-item-role text-xs fw-600 mb-8">
                    Founder & CEO
                  </div>
                  <p className="m-0 text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod.
                  </p>
                </div>
              </div>
            </div>
            {/* Repeat the same structure for other team members */}
          </div>
        </div>
      </div>
    </section>
  );
};

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
