import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};

const defaultProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined,
};

const Image = ({ src, width, height, alt, className, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const placeholderImage = document.createElement("img");
    handlePlaceholder(imageRef.current, placeholderImage);
  }, []);

  const placeholderSrc = (w, h) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
  };

  const handlePlaceholder = (img, placeholder) => {
    img.style.display = "none";
    img.before(placeholder);
    placeholder.src = placeholderSrc(width || 0, height || 0);
    placeholder.width = width;
    placeholder.height = height;
    placeholder.style.opacity = "0";
    className && placeholder.classList.add(className);

    img.addEventListener("load", () => {
      placeholder.remove();
      img.style.display = "";
      setIsLoaded(true);
    });
  };

  return (
    <img
      {...props}
      ref={imageRef}
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt}
    />
  );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
