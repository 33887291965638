import React, { useState } from "react";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Testimonial from "../components/sections/Testimonial";
import Clients from "../components/sections/Clients";
import Team from "../components/sections/Team";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
import Image from "../components/elements/Image";
import Input from "../components/elements/Input";
import ButtonGroup from "../components/elements/ButtonGroup";
import Button from "../components/elements/Button";
import Modal from "../components/elements/Modal";
import Accordion from "../components/elements/Accordion";
import AccordionItem from "../components/elements/AccordionItem";

const Secondary = () => {
  const [demoModalActive, setDemoModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setDemoModalActive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setDemoModalActive(false);
  };

  const genericSection01Header = {
    title: "Newsletter modal is a component commonly used",
  };

  const genericSection02Header = {
    title: "Button is a component commonly used",
  };

  const genericSection03Header = {
    title: "Input form is a component commonly used",
  };

  const genericSection04Header = {
    title: "Frequently asked questions",
  };

  return (
    <>
      <Testimonial className="has-bg-color-cut illustration-section-02" />
      <Clients topDivider bottomDivider />
      <Team />
      <FeaturesSplit invertMobile imageFill topDivider />

      <GenericSection topDivider>
        <div className="container-xs">
          <h2 className="mt-0">
            Lorem ipsum is placeholder text commonly used in the graphic.
          </h2>
          <p>
            Lorem ipsum dolor sit amet,{" "}
            <a href="#0">consectetur adipiscing elit</a>, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </p>
          <p>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </p>
          <figure>
            <Image
              className="image-larger"
              src={require("./../assets/images/image-placeholder.jpg")}
              alt="Placeholder"
              width={712}
              height={400}
            />
            <figcaption className="text-color-low">
              A super-nice image{" "}
              <span role="img" aria-label="mile">
                😀
              </span>
            </figcaption>
          </figure>
          <h4>Flexibility</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum.
          </p>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur.</li>
            <li>Lorem ipsum dolor sit amet, consectetur.</li>
            <li>Lorem ipsum dolor sit amet, consectetur.</li>
          </ul>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris.
          </p>
        </div>
      </GenericSection>

      <GenericSection topDivider>
        <div className="container-xs">
          <SectionHeader
            data={genericSection01Header}
            className="center-content"
          />
          <div className="center-content">
            <Button
              color="secondary"
              aria-controls="demo-modal"
              onClick={openModal}
            >
              Open modal
            </Button>
          </div>
          <Modal
            id="demo-modal"
            show={demoModalActive}
            handleClose={closeModal}
          >
            <div className="center-content">
              <h3 className="mt-16 mb-8">Join our newsletter</h3>
              <p className="text-sm">
                Magnis dis parturient montes nascetur. Quam quisque id diam vel
                quam integer.
              </p>
            </div>
            <form style={modalFormStyle}>
              <div className="mb-12">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  labelHidden
                />
              </div>
              <Button color="primary" wide>
                Subscribe
              </Button>
            </form>
            <div className="center-content mt-24">
              <a
                className="text-xs"
                aria-label="close"
                href="#0"
                onClick={closeModal}
              >
                No thanks!
              </a>
            </div>
          </Modal>
        </div>
      </GenericSection>

      <GenericSection topDivider className="center-content">
        <div className="container">
          <SectionHeader
            data={genericSection02Header}
            className="center-content"
          />
          <ButtonGroup>
            <Button color="primary" wideMobile>
              Get started now
            </Button>
            <Button color="secondary" wideMobile>
              Get started now
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button color="dark" wideMobile>
              Get started now
            </Button>
            <Button wideMobile>Get started now</Button>
          </ButtonGroup>
        </div>
      </GenericSection>

      <GenericSection topDivider>
        <div className="container-xs">
          <SectionHeader
            data={genericSection03Header}
            className="center-content"
          />
          <form style={formStyle}>
            <div className="mb-24">
              <Input
                type="email"
                label="This is a label"
                placeholder="Your best email.."
                formGroup="desktop"
                labelHidden
              >
                <Button color="primary">Early access</Button>
              </Input>
            </div>
            <div className="mb-24">
              <Input
                type="email"
                label="This is a label"
                placeholder="Your best email.."
                defaultValue="hello@cruip.com"
                formGroup="desktop"
                labelHidden
              >
                <Button color="primary" loading>
                  Early access
                </Button>
              </Input>
            </div>
            <div className="mb-24">
              <Input
                type="email"
                label="This is a label"
                placeholder="Your best email.."
                formGroup="desktop"
                labelHidden
                status="error"
                hint="Something is wrong."
              >
                <Button color="primary">Early access</Button>
              </Input>
            </div>
            <div className="mb-24">
              <Input
                type="email"
                label="This is a label"
                placeholder="Your best email.."
                formGroup="desktop"
                labelHidden
                status="success"
                hint="You've done it."
              >
                <Button color="primary">Early access</Button>
              </Input>
            </div>
          </form>
        </div>
      </GenericSection>

      <GenericSection topDivider>
        <div className="container-xs">
          <SectionHeader
            data={genericSection04Header}
            className="center-content"
          />
          <Accordion>
            <AccordionItem title="Nisi porta lorem mollis aliquam ut." active>
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </AccordionItem>
            <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </AccordionItem>
            <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </AccordionItem>
            <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </AccordionItem>
            <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups.
            </AccordionItem>
          </Accordion>
        </div>
      </GenericSection>

      <Cta invertColor split className="has-bg-color-cut" />
    </>
  );
};

const formStyle = {
  maxWidth: "420px",
  margin: "0 auto",
};

const modalFormStyle = {
  maxWidth: "320px",
  margin: "0 auto",
};

export default Secondary;
