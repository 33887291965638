import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import Checkbox from "../elements/Checkbox";

const LoginForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // Define outer and inner classes using classNames
  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  // Define the section header content
  const sectionHeader = {
    title: "Welcome back. We exist to make entrepreneurship easier.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* Render the section header */}
          <SectionHeader
            tag="h1"
            data={sectionHeader}
            className="center-content"
          />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                {/* Render the sign-in form */}
                <form>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="password"
                        label="Password"
                        placeholder="Password"
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mt-24 mb-32">
                      {/* Render the sign-in button */}
                      <Button color="primary" wide>
                        Sign in
                      </Button>
                    </div>
                    <div className="signin-footer mb-32">
                      {/* Render the "Remember me" checkbox and "Forgot password?" link */}
                      <Checkbox>Remember me</Checkbox>
                      <Link
                        to="/recover-password/"
                        className="func-link text-xs"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </fieldset>
                </form>
                {/* Render the sign-up link */}
                <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Don't have an account?{" "}
                    <Link to="/signup/" className="func-link">
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
