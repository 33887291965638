import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/LogoInvert";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import axios from "axios";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer invert-color center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );
  const [footerData, setFooterData] = useState(null);
  const fetchFooterData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/footer"
      );
      setFooterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);
  if (!footerData) {
    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top space-between text-xxs">
              <Logo />
              <FooterSocial />
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <FooterNav />
              <div className="footer-copyright">
                &copy; 2020 Tidy, all rights reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  } else {
    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top space-between text-xxs">
              <Logo />
              <FooterSocial />
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <FooterNav />
              <div className="footer-copyright" style={{ color: "white" }}>
                © Copyright {footerData.title} {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
