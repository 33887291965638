// Home.jsx
import React, { useEffect, useState } from "react";
import HeroSplit from "../components/sections/HeroSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import News from "../components/sections/News";
import Roadmap from "../components/sections/Roadmap";
import Pricing from "../components/sections/Pricing";
import Cta from "../components/sections/Cta";
import AccordionItem from "../components/elements/AccordionItem";
import GenericSection from "../components/sections/GenericSection";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Accordion from "../components/elements/Accordion";
import Testimonial from "../components/sections/Testimonial";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import axios from "axios";

const Home = () => {
  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/frequentQuestion"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const genericSection04Header = {
    title: "Frequently asked questions",
  };
  if (!data) {
    return (
      <>
        <HeroSplit hasBgColor invertColor />
        <FeaturesSplit id="about-us-section" />
        <FeaturesTiles topOuterDivider id="features-section" />
        <News className="illustration-section-01" />
        <Roadmap topOuterDivider />
        <Pricing hasBgColor pricingSlider id="pricing-section" />

        <Testimonial
          topOuterDivider
          className="has-bg-color-cut illustration-section-02"
        />
        <GenericSection topDivider id="faq-section">
          <div className="container-xs">
            <SectionHeader
              data={genericSection04Header}
              className="center-content"
            />
            <Accordion>
              <AccordionItem title="lorem" active>
                lorem
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta hasBgColor invertColor split id="contactsection" />
      </>
    );
  } else {
    return (
      <>
        <HeroSplit hasBgColor invertColor />
        {/*FeaturesSplit  = about us */}
        <FeaturesSplit id="about-us-section" />
        {/*FeaturesTiles  = features */}
        <FeaturesTiles topOuterDivider id="features-section" />
        <News className="illustration-section-01" />
        <Roadmap topOuterDivider />
        <Pricing hasBgColor pricingSlider id="pricing-section" />

        <Testimonial
          topOuterDivider
          className="has-bg-color-cut illustration-section-02"
        />
        {/*GenericSection  = faq */}
        <GenericSection topDivider id="faq-section">
          <div className="container-xs">
            <SectionHeader
              data={genericSection04Header}
              className="center-content"
            />
            <Accordion>
              {data.map((faq) =>
                faq.active ? (
                  <AccordionItem key={faq.DT_RowId} title={faq.question} active>
                    {faq.response}
                  </AccordionItem>
                ) : null
              )}
            </Accordion>
          </div>
        </GenericSection>
        {/*Contact  = cta */}
        <Cta hasBgColor invertColor split id="cta" />
      </>
    );
  }
};

export default Home;
