import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import axios from "axios";

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://localhost:1234/crm-module/aboutus"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow that just works",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };
  if (!data) {
    return (
      <>
        <section {...props} className={outerClasses}>
          <div className="container">
            <div className={innerClasses}>
              <div className={splitClasses}>
                <div className="split-item">
                  <div className="split-item-content center-content-mobile">
                    <h3
                      className="mt-0 mb-16 reveal-from-bottom"
                      data-reveal-container=".split-item"
                    >
                      About us
                    </h3>
                    <p
                      className="m-0 reveal-from-bottom"
                      data-reveal-delay="100"
                      data-reveal-container=".split-item"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                  <div
                    className={classNames(
                      "split-item-image center-content-mobile reveal-scale-up",
                      imageFill && "split-item-image-fill"
                    )}
                    data-reveal-container=".split-item"
                    data-reveal-delay="200"
                  >
                    <Image
                      className="has-shadow"
                      src={require("./../../assets/images/features-split-image-01.png")}
                      alt="Features split 01"
                      width={528}
                      height={396}
                    />
                  </div>
                </div>

                {/* Repeat the same structure for other split items */}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    {data.title}
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    {data.description}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/features-split-image-01.png")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              {/* Repeat the same structure for other split items */}
            </div>
          </div>
        </div>
      </section>
    );
  }
};

FeaturesSplit.propTypes = {
  ...SectionSplitProps.types,
};

FeaturesSplit.defaultProps = {
  ...SectionSplitProps.defaults,
};

export default FeaturesSplit;
