import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  children: null,
  active: false,
  title: "",
};

const AccordionItem = ({ className, children, active, title, ...props }) => {
  const [isActive, setIsActive] = useState(active);
  const contentRef = useRef(null);

  const openItem = () => {
    contentRef.current.style.maxHeight = contentRef.current.scrollHeight + "px";
    setIsActive(true);
  };

  const closeItem = () => {
    contentRef.current.style.maxHeight = null;
    setIsActive(false);
  };

  const handleClick = () => {
    isActive ? closeItem() : openItem();
  };

  const classes = classNames(isActive && "is-active", className);

  return (
    <li {...props} className={classes}>
      <div className="accordion-header text-sm" onClick={handleClick}>
        <span className="h6 m-0">{title}</span>
        <div className="accordion-icon"></div>
      </div>
      <div ref={contentRef} className="accordion-content text-xs">
        <p>{children}</p>
      </div>
    </li>
  );
};

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;

export default AccordionItem;
