import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";

import Image from "../elements/Image";
import Modal from "../elements/Modal";

const HeroSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const [videoModalActive, setVideoModalActive] = useState(false);

  const openVideoModal = (e) => {
    e.preventDefault();
    setVideoModalActive(true);
  };

  const closeVideoModal = (e) => {
    e.preventDefault();
    setVideoModalActive(false);
  };

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                <h1 className="mt-0 mb-16">
                  <span style={{ color: "#37b36c" }}>C</span>li
                  <span style={{ color: "#37b36c" }}>C</span>laim
                </h1>{" "}
                <p className="mt-0 mb-32">
                  Our landing page template works on all devices, so you only
                  have to set it up once, and get beautiful results forever.
                </p>
              </div>
              <div className="hero-figure split-item-image split-item-image-fill illustration-element-01 reveal-from-bottom">
                <a
                  data-video="https://player.vimeo.com/video/174002812"
                  href="#0"
                  aria-controls="video-modal"
                  onClick={openVideoModal}
                >
                  <Image
                    src={require("./../../assets/images/video-placeholder.jpg")}
                    alt="Hero"
                    width={528}
                    height={396}
                  />
                </a>
              </div>
              <Modal
                id="video-modal"
                show={videoModalActive}
                handleClose={closeVideoModal}
                video="https://player.vimeo.com/video/174002812"
                videoTag="iframe"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSplit.propTypes = SectionSplitProps.types;
HeroSplit.defaultProps = SectionSplitProps.defaults;

export default HeroSplit;
